import React from "react";
import SiderBar from "../SideBar";
import Wishlistcard from "../Wishlist-card";

import { useEffect, useState } from "react";

import { FiSearch, FiFilter } from "react-icons/fi";
import Header from "../Header";
import { useQuery } from "react-query";

import * as serviceCaller from "../../Services/index";
import Filter from "../Filter";
import Loader from "../Loader";
import { Helmet } from "react-helmet";
import { getPurchased } from "../../Services/index";
function Purchased() {
  let [purchasedapiData, setPurchasedapiData] = useState([]);
  const [category, setCategory] = useState("all");
  const [Searchdata, setSearchdata] = useState();
  const [searchdealdata, setSearchdealdata] = useState([]);
  const [categoryName, setCategoryName] = useState(null);
  let [categoryFilter, setCategoryFilter] = useState(null);

  let [AllCategory, setAllCategory] = useState([]);
  let [page, setPage] = useState(1);
  let [pagesize, setPagesize] = useState(5);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    serviceCaller.getMyPurchasedCategory().then((item) => {
      setAllCategory(item?.data?.unique);

    });
  }, []);

  useEffect(() => {
    getMyTodayDeals(page, pagesize, category , Searchdata);
  }, [page, pagesize, category, refresh]);

  const getMyTodayDeals = async (
    page = 1,
    page_size = 10,
    category = "all",
    Searchdata = "",
    includes = "favorites likes purchases is_not"
  ) => {
    let resp = await serviceCaller.getPurchased(
      page,
      page_size,
      category,
      Searchdata,
      includes
    );
    setPurchasedapiData(resp.data);
  };

  // category filter
  const handleFilter = (e) => {
    // console.log(e);
    setCategory(e.target.value);
  };
  useEffect(() => {
    getPurchased(page, pagesize, category , Searchdata);
  }, [page, pagesize, category, Searchdata ,  refresh]);

  const getPurchased = async (
    page = 1,
    page_size = 10,
    category = "all",
    Searchdata = "",
    includes = "favorites likes purchases is_not"
  ) => {
    let resp = await serviceCaller.getPurchased(
      page,
      page_size,
      category,
      Searchdata,
      includes
    );
    setPurchasedapiData(resp.data);
  };
  // console.log("categorygetpurchased", categoryFilter);
//  console.log("sds" , Searchdata);
  return (
    <>
      <Helmet>
        <title>Purchased - Amz Deals MarketPlace Dashboard</title>
      </Helmet>
      <Header />
      <div className="container">
        <SiderBar />
        <div className="container-width">
          <div className="wishlist-header">
            <div className="w-70">
              <h3> Purchased</h3>
            </div>
            <div className="dealsmp-searchform">
                  <input
                    type="text"
                    placeholder="Search Here"
                    onChange={(e) => {
                      setSearchdata(e.target.value);
                    }}
                  />
                  <button >
                    <FiSearch />
                  </button>
                </div>
            {/* <div className="producttab-center pt-2 pb-2">
              <select
                className="category-dropdown"
                onChange={handleFilter}
              >
                <option selected value="all">
                  All Categories
                </option>
                {AllCategory &&
                  AllCategory.map((item) => (
                    <option value={item.category_id} key={item.category_id}>
                      {item?.cat_lists[0].name}
                    </option>
                  ))}
              </select>
            </div> */}
          </div>
          <div className="productcard productcard-title">
        <div className="productheader-dealmp-container pt-2 pb-2 pr-2 pl-2 purchased-header">
       
        <table  className="purchased-table-res">
            <tr>
              <th>Store Name</th>
              <th>Category</th>
              <th>BSR Rank</th>
              <th>Profit</th>
              <th>ROI</th>
              <th>Country</th>
              <th>Created on</th>
              <th style={{visibility:"hidden"}}>...</th>
            </tr>
            {/* <tr>
              <td>{value.store_name}</td>
              <td>{value?.cat_lists[0]?.name
                      ? value?.cat_lists[0]?.name
                      : "Uncategorized"}</td>
              <td>{`${value.ninety_days_rank ?? 0}`}</td>
              <td>${value.net_profit}</td>
                  <td>{value.roi}%</td>
                  <td>{value.country_name}</td>
                  <td>{moment(value.created_on).format("L")}</td>
                  <td>
                    {value?.asin ? (
                      <button
                        className="graph-btn"
                        onClick={() => handlesViewdeal(value.store_name)}
                      >
                        view deal
                      </button>
                    ) : (
                      <button
                        className={"amazon-btn dis-block"}
                        onClick={() => handleshowdeal(value)}
                        disabled={disablebutton}
                      >
                        buy deal
                      </button>
                    )}
                  </td>
            </tr> */}
           
          </table>
          
          {/* <h4>Store name</h4>
          <h4>Category</h4>
          <h4>Bsr rank</h4>
          <h4>Profit</h4>
          <h4>Roi</h4>
          <h4>Country</h4>

          <h4>Created on</h4> */}
        </div>
        {/* </div> */}
      </div>
          <div className="min-container-wishlist pb-2">
          <div class="purchased-list-res" style={{ overflowY:"overlay" ,  height: "60vh" }}>
            {purchasedapiData.docs ? (
              purchasedapiData.docs.length > 0 ? (
                purchasedapiData.docs &&
                purchasedapiData.docs.map((value) => (
                  <Wishlistcard
                    value={value}
                    Refresh={() => {
                      setRefresh(!refresh);
                    }}
                  />
                ))
              ) : (
                <>
                  <div className="product-container pb-2 emptyproducts-purchased">
                    <p className="empty-content">
                      No Products Found
                      {/* Sorry! You haven't added any */}
                      <span className="orange ml-2 mr-2">
                        {categoryName} 
                      </span>
                    </p>
                  </div>
                </>
              )
            ) : (
              <>
                <Loader />
              </>
            )}

            {/* {purchasedapiData.docs && purchasedapiData.docs.length <= 0 && 
              <>
                <div className="product-container pb-2 ">
                  <p className="empty-content">Sorry! You haven't added any product to Purchased. </p>
                </div>
              </>
            } */}

            {/* {purchasedapiData.docs && purchasedapiData.docs.length >= 0 ? (
              purchasedapiData.docs &&
              purchasedapiData.docs.map((value) => (
                <Wishlistcard value={value} 
                refetch={refetch}
                />
              ))
            ) : (
              <>
               
                <Loader/>
              </>
            )} */}
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Purchased;
