import React from "react";
// import SiderBar from "../components/SideBar";
import SiderBar from "../SideBar";
import Wishlistcard from "../Wishlist-card";
// import { FiSearch, FiFilter } from "react-icons/fi";
import { useEffect, useState } from "react";
import Header from "../Header";
import { useQuery } from "react-query";

import * as serviceCaller from "../../Services/index";
import Filter from "../Filter";
import Loader from "../Loader";
import {Helmet} from "react-helmet";
function Wishlist() {
  let [dataApi, setDataApi] = useState([]);
  const [category, setCategory] = useState("all");
  const [categoryName,setCategoryName] = useState(null);
  let [categoryFilter, setCategoryFilter] = useState(null);
  let [AllCategory, setAllCategory] = useState([]);
  let [page,setPage]=useState(1);
  let [pagesize,setPagesize]=useState(5);
  const [refresh, setRefresh] = useState(false);


  useEffect(()=>{
    serviceCaller.getMyWishlistCategory()
    .then((item)=>{
      setAllCategory(item?.data?.unique)
    });
  },[]);
  
  useEffect(()=>{
    getMyTodayDeals(page,pagesize,category);
  },[page,pagesize,category,refresh]);

  const getMyTodayDeals=async(
    page= 1,
    page_size= 10,
    category="all",
    includes= "favorites likes purchases is_not",
  )=>{
    let resp =await serviceCaller.getWishlist(
      page,
      page_size,
      category,
      includes,
    );
    setDataApi(resp.data);
  }
  // console.log("categoryget", categoryFilter);

  // fetching wishlistdata

  // const { error,refetch } = useQuery(
  //   [
  //     "getwishlistData",
  //     { 
  //       categoryid: categoryFilter,
  //       includes: "purchases",
  //     },
  //   ],
  //   async ({ queryKey }) => {
  //     let { categoryid,includes } = queryKey[1];
  //     try {
  //       let res = await serviceCaller.getWishlist( categoryid,includes );
  //       // console.log(">>>>>>>>>>>>>>>>>>>>>>category", res.data);
  //       setDataApi(res.data);
  //     } catch (error) {
  //       // console.log(error);
  //     }
  //     // await serviceCaller.getCategory().then((res) => setCategory(res.data))
      
  //   }
    
  // );
  // if (error) return "An error has occurred: " + error.message;

 
  // category filter
  const handleFilter = (e) => {
    // console.log(e);
    setCategory(e.target.value);
  };

  return (
    <>
    <Helmet>
  <title>Wishlist - AOA Dashboard</title>
  </Helmet>
      <Header />
      <div className="container">
        <SiderBar />
        <div className="container-width">
          <div className="wishlist-header">
            <div className="">
              <h3>Wishlist</h3>
            </div>
            
          <div className="producttab-center pt-2 pb-2">
            {/* <span className="category-dropdown-span"> */}
              <select
                className="category-dropdown"
                // defaultValue={values}
                onChange={handleFilter}
              >
                <option selected value="all">
                  All Categories
                </option>
                {AllCategory &&
                  AllCategory.map((item) => (
                    <option value={item.category_id} key={item.category_id}>
                      {item?.cat_lists[0].name}
                    </option>
                  ))}
              </select>
            {/* </span> */}
          </div>
          </div>

          <div className="min-container-wishlist pb-2">

        
          
          <div className="productcard">
        <div className="productheader-dealmp-container pt-2 pb-2 pr-2 pl-2 ">
       
        <table>
            <tr>
              <th>Store name</th>
              <th>Category</th>
              <th>Bsr rank</th>
              <th>Profit</th>
              <th>Roi</th>
              <th>Country</th>
              <th>Created on</th>
              <th style={{visibility:"hidden"}}>...</th>
            </tr>
            {/* <tr>
              <td>{value.store_name}</td>
              <td>{value?.cat_lists[0]?.name
                      ? value?.cat_lists[0]?.name
                      : "Uncategorized"}</td>
              <td>{`${value.ninety_days_rank ?? 0}`}</td>
              <td>${value.net_profit}</td>
                  <td>{value.roi}%</td>
                  <td>{value.country_name}</td>
                  <td>{moment(value.created_on).format("L")}</td>
                  <td>
                    {value?.asin ? (
                      <button
                        className="graph-btn"
                        onClick={() => handlesViewdeal(value.store_name)}
                      >
                        view deal
                      </button>
                    ) : (
                      <button
                        className={"amazon-btn dis-block"}
                        onClick={() => handleshowdeal(value)}
                        disabled={disablebutton}
                      >
                        buy deal
                      </button>
                    )}
                  </td>
            </tr> */}
           
          </table>
          
          {/* <h4>Store name</h4>
          <h4>Category</h4>
          <h4>Bsr rank</h4>
          <h4>Profit</h4>
          <h4>Roi</h4>
          <h4>Country</h4>

          <h4>Created on</h4> */}
        </div>
        {/* </div> */}
      </div>
        {
          dataApi.docs ? (
              dataApi.docs.length > 0 ? (
                dataApi.docs &&
                dataApi.docs.map((value) => (
                  <Wishlistcard
                    value={value} 
                    dataApi={dataApi}
                    Refresh={()=>{
                      setRefresh(!refresh);
                    }}
                  />
                ))
              ) : (
                <>
                  <div className="product-container pb-2 ">
                    <p className="empty-content">
                      Sorry! You haven't added any
                      <span className="orange ml-2 mr-2">
                        {categoryName} category
                      </span>
                      product to Wishlist.
                    </p>
                  </div>
                {/* <Loader /> */}

                </>
              )
            ) : (
              <>
                <Loader />
              </>
            )}



            {/* {dataApi.docs && dataApi.docs.length <= 0 &&
             <>
                <div className="product-container pb-2 ">
                  <p className="empty-content">Sorry! Your wishlist is empty.</p>
                </div>
              </>   
             } */}
{/* {dataApi.docs && dataApi.docs.length >= 0 ? (
              dataApi.docs &&
              dataApi.docs.map((value) => (
                <Wishlistcard
                  value={value}
                  refetch={refetch}
                 
                  dataApi={dataApi}
                />
              ))
            ) : (
              <><Loader/></>
            )} */}



          </div>
        </div>
      </div>
    </>
  );
}
export default Wishlist;
