import React from "react";
// import SiderBar from '../components/SideBar';
// import Topbar from '../components/Topbar'
// import ProductTab from "../components/Producttab";
import SiderBar from "../SideBar";
import ProductTab from "../Home/Producttab";
import { useState,useEffect } from "react";
import { useQuery } from "react-query";

import * as serviceCaller from "../../Services/index";
import {Helmet} from "react-helmet";

export default function Home() {
  let [allDeal, setAllDeal] = useState([]);
  let [sort, setSort] = useState("-_id");
  let [render, setRender] = useState("render");
  const [refresh, setRefresh] = useState(false);
  let [todaydeal, setTodaydeal] = useState([]);
  let [searchterm, setSearchterm] = useState(null);
  const [userData, setUserData] = useState();
  let [plan, setPlan] = useState(null);
  let [planlist, setPlanlist] = useState(null);
  let [category, setCategory] = useState(null);
  let [pagesize,setPagesize]=useState(20)
  const [loading,setLoading] = useState(false); 
  let pagesizeset = ()=>{
    setPagesize(pagesize+20);
  }
  useEffect(() => {
    getUser();
  }, [refresh]);


  const {  error, refetch } = useQuery(
    [
      `Deals`,
      {
        sortBy: sort,
        category_id: category ,
        name: searchterm,
        plan_type: plan,
        list_id: planlist,
        includes: "favorites likes purchases is_not",
        page_size:pagesize
      },
    ],
    async ({ queryKey }) => {
      let { sortBy, category_id, name, plan_type, list_id, includes,page_size } = queryKey[1];
      // let {category_id}=queryKey[2];
      // let res = await serviceCaller.getAllDeals({
      //   sortBy,
      //   category_id,
      //   name,
      //   plan_type,
      //   list_id,
      //   includes,
      //   page_size
      // });
      // setAllDeal(res.data);

      // // console.log({ res });
    }
  );

  const getUser = async () => {
    setLoading(true)
    const response = await serviceCaller.getProfile();
    setUserData(response?.data?.UserData);
    setLoading(false)
  };

  // if (isLoading) return "Loading....";

  if (error) return "An error has occurred: " + error.message;

  return (
    <>

  <Helmet>
  <title>Log In for Global VA-Selected Profitable Deals | Dashboard</title>
  </Helmet>
      <div className="container">
        <SiderBar />
        <div className="container-width">
          {/* <Topbar /> */}
          <ProductTab
            loading={loading}
            allDeal={allDeal ? allDeal : []}
            todaydeal={todaydeal ? todaydeal : []}
            setSort={setSort}
            categoryGet={setCategory}
            render={render}
            setSearchterm={setSearchterm}
            setPlan={setPlan}
            Loginuser={userData}
            setPlanlist={setPlanlist}
            refetch={refetch}
            Refresh={async()=>{
              setRefresh(!refresh);
            }}
            pagesizeset={pagesizeset}
        />
        </div>
      </div>
    </>
  );
}
