import Productcard from "./Productcard";
import ReactPaginate from "react-paginate";
import { FiSearch } from "react-icons/fi";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { BiDownload } from "react-icons/bi";
import { GiTwoCoins } from "react-icons/gi";
import { GrAddCircle, GrCircleQuestion } from "react-icons/gr";
import { toast } from "react-toastify";

import Modal from "react-modal";
import Header from "../Header";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import * as serviceCaller from "../../Services/index";
import Loader from "../Loader";
import RequestedDealsRed from "../../assets/img/RequestedDealsRed.svg";
import CustomdealForm from "../CustomdealForm";
function ProductTab({
  setSort,
  categoryGet,
  render,
  setSearchterm,
  Loginuser,
  setPlan,
  Refresh,
  refetch,
  loading
}) {
  const {
    reset,
    formState: { errors },
  } = useForm();
  const [category, setCategory] = useState("all");
  const [Plans, setPlans] = useState([]);
  let [sort, setSortValue] = useState("default_sort");
  let [SelectedPlan, setSelectedPlan] = useState("all");
  let [page, setPage] = useState(1);
  let [pagesize, setPagesize] = useState(5);
  let [allDeal, setAllDeal] = useState([]);
  let [requestedDeal, setRequestedDeal] = useState([]);
  const [userData, setUserData] = useState();
  const [user, setUser] = useState(Loginuser);
  let [AllCategory, setAllCategory] = useState([]);
  const [Searchdata, setSearchdata] = useState();
  const [refresh, setRefresh] = useState(false);
  const [alldealpagesize, setAlldealpagesize] = useState(10);
  const [searchdealdata, setSearchdealdata] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [TotalCoin, setTotalCoin] = useState(0);
  const [coinvalue, setCoinvalue] = useState(50);
  const [pagenumber, setPagenumber] = useState(1);
  const [page_size, setpage_size] = useState(10);
  const [pageWidth, setpageWidth] = useState(0);
  const [disablebutton, setDisablebutton] = useState(false);
  const [activetab, setActivetab] = useState("alldeal");
  const [activetabheader, setActivetabHeader] = useState("alldeal");
  const [isOpen, setIsOpen] = useState(false);
  console.log("opens", isOpen);
  useEffect(() => {
    getUser();
    handleDealsort();
  }, [refresh]);

  useEffect(() => {
    getMyAllDeals(page, page_size, sort, "all", category, Searchdata, null);
    getRequestedDeals(page, page_size, sort, "all", category, Searchdata, null);
    // getUser();
  }, [
    page,
    sort,
    alldealpagesize,
    SelectedPlan,
    category,
    Searchdata,
    refresh,
  ]);
  useEffect(() => {
    const Userplans = serviceCaller.getUserPlan().then((res) => {
      setPlans(res?.data?.Plans);
    });
  }, [refresh]);

  useEffect(() => {
    serviceCaller.getMyCategory().then((item) => {
      setAllCategory(item?.data?.unique);
    });
  }, [refresh]);

  const getUser = async () => {
    const response = await serviceCaller.getProfile();
    console.log("response?.data?.UserData",response?.data?.UserData)
    setUserData(response?.data?.UserData);
  };

  useEffect(() => {
    setpageWidth(window?.screen?.width);
  }, [window]);

  const getMyAllDeals = async (
    page = pagenumber,
    page_size = 10,
    sort,
    SelectedPlan = "all",
    category = "",
    Searchdata = "",
    includes = "favorites likes purchases is_not"
  ) => {
    let res = await serviceCaller.getMyDeals({
      page,
      page_size,
      sort,
      SelectedPlan,
      category,
      Searchdata,
      includes,
    });
    setAllDeal(res?.data);
    setSearchdealdata(res?.data?.docs);
  };

  const getRequestedDeals = async (
    page = pagenumber,
    page_size = 10,
    sort,
    SelectedPlan = "all",
    category = "",
    Searchdata = "",
    includes = "favorites likes purchases is_not"
  ) => {
    let res = await serviceCaller.getMyRequestedDeals({
      page,
      page_size,
      sort,
      SelectedPlan,
      category,
      Searchdata,
      includes,
    });
    setRequestedDeal(res?.data?.docs);
  };

  const pagesvisited = pagenumber * alldealpagesize;
  const slicedData = searchdealdata?.slice(
    pagesvisited,
    pagesvisited + alldealpagesize
  );
  // pageCount
  const pageCount = Math.ceil(allDeal?.totalDocs / alldealpagesize);
  const handlePageClick = ({ selected }) => {
    setPage(selected + 1);
    setSelectedData([]);
    setTotalCoin(0);
  };

  let pagesizeset = () => {
    setPagesize(pagesize + 5);
  };

  // deal sort
  const handleDealsort = async (e) => {
    const select = e?.target?.value;
    setSortValue(select);
    setSelectedData([]);
    setTotalCoin(0);
  };
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  // console.log("TotalCoin", TotalCoin);
  // console.log("coinvalue", coinvalue);
  console.log("localStorage",localStorage)
  console.log("userData",userData);
  console.log("userData?.credit_deals-typeof",typeof (userData?.credit_deals))
  console.log("userData?.credit_deals",userData?.credit_deals);
  console.log("searchdealdata",searchdealdata);
  return (
    <>
      <Header />
      <div className="topbar mt-2">
        <div class="topParent">
          <div className="topbar-container">
            <div className="topbar-list-1 top-bg-white">
              <div className="topbar-list-items-1">
                <div className="topbar-list-items-1-div">
                  <div className="dealsmp-searchform">
                    <input
                      type="text"
                      placeholder="Search Here"
                      onChange={(e) => {
                        setPage(1);
                        setSearchdata(e.target.value);
                      }}
                    />
                    <button>
                      <FiSearch />
                    </button>
                  </div>
                </div>
               
                {/* {pageWidth > 519  && ( */}
                <div className="topbar-list-items-1-div">
                  <select
                    className="dealsmp-sort-filter"
                    onChange={handleDealsort}
                  >
                    <option>Default Sort </option>

                    <option value="bsr-lth">BSR High to Low</option>
                    <option value="bsr-htl">BSR Low to High</option>
                    <option value="store-atz">Store A to Z</option>
                    <option value="store-zta">Store Z to A</option>
                  </select>
                </div>
                <div className="topbar-list-items-1-div">
                  {/* {activetab === "reqdeal" && ( */}
                  <button
                    style={{ display: "flex", alignItems: "center" }}
                    type="link"
                    className={
                      "sideBulkbuydeal-btn dis-block header-request-btn"
                    }
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    Request Deals
                    {/* <GrAddCircle /> */}
                  </button>
                  {/* )}  */}
                  {/* <button
                    className={"sideBulkbuydeal-btn dis-block"}
                    style={{ float: "right" }}
                  >
                    Request Deals
                  </button> */}
                </div>
                {/* )} */}
              </div>
            </div>
            <div className="topbar-img-list-3 top-bg-white text-center pt-2">
              <a
                href="/getcoins"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <div class="remainingcoins-text">
                  <h3>Remaining Coins</h3>
                  <h2 className="mt-2 dmp-rm-container">
                    <span className="dmp-rm-icon">
                      <GiTwoCoins />
                    </span>
                    <span class="remaining-coin-datas">
                      {
                      
                      userData?.credit_deals ?? 0
                       }
                    </span>
                  </h2>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      {
      !Loginuser ||
      loading 
      
      ? 
      <div className="productcard-status-container" style={{height:"90vh" , overflowY:"hidden"}}>
      {/* <div className="productcard-status-wrapper"> */}
    
     <Loader/>
    
      {/* </div> */}
      </div>
      : (
        
        <>
          <div className="productcard-status-container">
            <div className="productcard-status-wrapper">
              <h1
                className={
                  activetab === "alldeal"
                    ? "alldeal-title-active"
                    : "alldeal-titles"
                }
                onClick={() => setActivetab("alldeal")}
              >
                All Deals
              </h1>
              <div style={{ display: "flex", alignItems: "center" }}>
                {Loginuser?.request_deal_status === "completed" &&
                activetab !== "reqdeal" ? (
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={RequestedDealsRed}
                      className="pricing-icon"
                      alt="checkicon"
                      onClick={async () => {
                        let data = {
                          request_deal_status: "read",
                        };
                        const response = await serviceCaller.UpdateUser(
                          Loginuser?._id,
                          data
                        );
                       console.log("image this is loading response",response)
                        Refresh();
                        setActivetab("reqdeal");
                      }}
                    />
                  </button>
                ) : (
                  <>
                    <h1
                      className={
                        activetab === "reqdeal"
                          ? "reqdeal-title-active"
                          : "alldeal-titles"
                      }
                      onClick={() => {
                        setActivetab("reqdeal");
                      }}
                    >
                      Requested Deals
                    </h1>
                    {activetab === "reqdeal" && (
                      <button
                        style={{ display: "flex", alignItems: "center" }}
                        type="link"
                        className="request-btn"
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                      >
                        <GrAddCircle />
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="productcard card-table productcard-title desktop-home-table-title">
            <div className="productheader-dealmp-container pt-2 pb-2 pr-2 pl-2 ">
              <table class="tabletitle">
                <tr>
                  <th></th>
                  <th>Store Name</th>
                  <th>Category</th>
                  <th>BSR Rank</th>
                  <th>Profit</th>
                  <th>ROI</th>
                  <th>Country</th>
                  <th>Created on</th>
                  <th style={{ visibility: "hidden" }}>...</th>
                </tr>
              </table>
            </div>
            {/* </div> */}
          </div>
          <div className="productcard-wrapper"></div>
          {activetab === "alldeal" ? (
            <div>
              {searchdealdata?.length == 0 ? (
                <div className="product-container width-95">
                  <p className="empty-content ">No products Found </p>
                </div>
              ) : (
                <>
                  <div class="ProductCard-ScreenFrezze">
                    
                    {
                    searchdealdata?.map((value) => (
                      <div>
                        <Productcard
                          value={value}
                          TotalDeals={allDeal}
                          render={render}
                          refetch={refetch}
                          refresh={() => {
                            setTotalCoin(0);
                            setSelectedData([]);
                            getUser();
                            getMyAllDeals(
                              page,
                              page_size,
                              sort,
                              "all",
                              category,
                              Searchdata,
                              null
                            );
                          }}
                          selectDeal={(value) => {
                            let arrayValue = [...selectedData];
                            if (
                              arrayValue.some(
                                (e) => e._id == value?._id?.toString()
                              )
                            ) {
                              let TotalcoinValue = TotalCoin;
                              TotalcoinValue =
                                TotalcoinValue - parseInt(value.deal_price);

                              setTotalCoin(TotalcoinValue);
                              arrayValue = arrayValue.filter(
                                (item) => item !== value
                              );
                            } else {
                              let TotalcoinValue = TotalCoin;
                              TotalcoinValue =
                                TotalcoinValue + parseInt(value.deal_price);
                              setTotalCoin(TotalcoinValue);

                              arrayValue.push(value);
                            }
                            setSelectedData(arrayValue);
                          }}
                          setCoinvalue={setCoinvalue}
                          coinvalue={coinvalue}
                          userData={userData}
                        />
                      </div>
                    ))
                    
                    }
                 
                  </div>
                  {searchdealdata?.length >= 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel=">"
                          // pageRangeDisplayed={5}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={1}
                          pageCount={pageCount}
                          onPageChange={handlePageClick}
                          previousLabel="<"
                          renderOnZeroPageCount={null}
                          containerClassName={"paginationbttns"}
                          previousLinkClassName={"previousbttns"}
                          nextLinkClassName={"nextbttns"}
                        />
                      </div>
                      <div class="sideBulkdealwrapper">
                        {selectedData?.length > 1 && (
                          <>
                            <button
                              className={"sideBulkbuydeal-btn dis-block"}
                              style={{ float: "right" }}
                              onClick={async () => {
                                setDisablebutton(true);
                                let buyDeal = await serviceCaller.buyBulkDeal(
                                  selectedData
                                );

                                if (buyDeal.status == 200) {
                                  setTotalCoin(0);
                                  getUser();

                                  getMyAllDeals(
                                    page,
                                    page_size,
                                    sort,
                                    "all",
                                    category,
                                    Searchdata,
                                    null
                                  );
                                  //  setSelectedData();
                                  setSelectedData([]);
                                  // setRefresh(!refresh);
                                  if (
                                    buyDeal.data.message === "Deals Purchased"
                                  ) {
                                    toast.success(buyDeal.data.message);
                                    // window.location.reload();
                                  } else {
                                    toast.error(buyDeal.data.message);
                                  }
                                  setDisablebutton(false);
                                }
                              }}

                              disabled={disablebutton}
                            >
                              buy deals
                            </button>
                            <h5 class="buydealPrice-icon bulkdealsPriceicon">
                              {" "}
                              <GiTwoCoins /> {`${TotalCoin}`} Coins
                            </h5>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : activetab === "reqdeal" ? (
            <div>
              {requestedDeal?.length == 0 ? (
                <div className="product-container width-95">
                  <p className="empty-content ">No products Found </p>
                </div>
              ) : (
                <>
                  <div class="ProductCard-ScreenFrezze">
                    {requestedDeal?.map((value) => (
                      <div>
                        <Productcard
                          value={value}
                          TotalDeals={allDeal}
                          render={render}
                          refetch={refetch}
                          refresh={() => {
                            setTotalCoin(0);
                            setSelectedData([]);
                            getUser();
                            getMyAllDeals(
                              page,
                              page_size,
                              sort,
                              "all",
                              category,
                              Searchdata,
                              null
                            );
                          }}
                          selectDeal={(value) => {
                            let arrayValue = [...selectedData];
                            if (
                              arrayValue.some(
                                (e) => e._id == value?._id?.toString()
                              )
                            ) {
                              let TotalcoinValue = TotalCoin;
                              TotalcoinValue =
                                TotalcoinValue - parseInt(value.deal_price);

                              setTotalCoin(TotalcoinValue);
                              arrayValue = arrayValue.filter(
                                (item) => item !== value
                              );
                            } else {
                              let TotalcoinValue = TotalCoin;
                              TotalcoinValue =
                                TotalcoinValue + parseInt(value.deal_price);
                              setTotalCoin(TotalcoinValue);

                              arrayValue.push(value);
                            }
                            setSelectedData(arrayValue);
                          }}
                          setCoinvalue={setCoinvalue}
                          coinvalue={coinvalue}
                          userData={userData}
                        />
                      </div>
                    ))}
                  </div>
                
                </>
              )}
            </div>
          ) : (
            <div>
              {searchdealdata?.length !== 0 ? (
                <div className="product-container width-95">
                  <div
                    className="empty-content"
                    style={{ flexDirection: "column" }}
                  >
                    <p>
                      Still looking for deals? Send in your requirements and our
                      VA will fetch them for you.
                    </p>
                    <button
                      style={{ display: "block", marginTop: "10px" }}
                      className="amazon-btn"
                      onClick={toggleModal}
                    >
                      Request Here
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div class="ProductCard-ScreenFrezze">
                    {searchdealdata?.map((value) => (
                      <div>
                        <Productcard
                          value={value}
                          TotalDeals={allDeal}
                          render={render}
                          refetch={refetch}
                          refresh={() => {
                            setTotalCoin(0);
                            setSelectedData([]);
                            getUser();
                            getMyAllDeals(
                              page,
                              page_size,
                              sort,
                              "all",
                              category,
                              Searchdata,
                              null
                            );
                          }}
                          selectDeal={(value) => {
                            let arrayValue = [...selectedData];

                            if (
                              arrayValue.some(
                                (e) => e._id == value?._id?.toString()
                              )
                            ) {
                              let TotalcoinValue = TotalCoin;
                              TotalcoinValue =
                                TotalcoinValue - parseInt(value.deal_price);

                              setTotalCoin(TotalcoinValue);
                              arrayValue = arrayValue.filter(
                                (item) => item !== value
                              );
                            } else {
                              let TotalcoinValue = TotalCoin;
                              TotalcoinValue =
                                TotalcoinValue + parseInt(value.deal_price);
                              setTotalCoin(TotalcoinValue);

                              arrayValue.push(value);
                            }
                            setSelectedData(arrayValue);
                          }}
                          setCoinvalue={setCoinvalue}
                          coinvalue={coinvalue}
                          userData={userData}
                        />
                      </div>
                    ))}
                  </div>
                  {searchdealdata?.length >= 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel=">"
                          // pageRangeDisplayed={5}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={1}
                          pageCount={pageCount}
                          onPageChange={handlePageClick}
                          previousLabel="<"
                          renderOnZeroPageCount={null}
                          containerClassName={"paginationbttns"}
                          previousLinkClassName={"previousbttns"}
                          nextLinkClassName={"nextbttns"}
                        />
                      </div>
                      <div class="sideBulkdealwrapper">
                        {selectedData?.length > 1 && (
                          <>
                            <button
                              className={"sideBulkbuydeal-btn dis-block"}
                              style={{ float: "right" }}
                              onClick={async () => {
                                setDisablebutton(true);
                                let buyDeal = await serviceCaller.buyBulkDeal(
                                  selectedData
                                );

                                if (buyDeal.status == 200) {
                                  setTotalCoin(0);
                                  getUser();

                                  getMyAllDeals(
                                    page,
                                    page_size,
                                    sort,
                                    "all",
                                    category,
                                    Searchdata,
                                    null
                                  );
                                  //  setSelectedData();
                                  setSelectedData([]);
                                  // setRefresh(!refresh);
                                  if (
                                    buyDeal.data.message === "Deals Purchased"
                                  ) {
                                    toast.success(buyDeal.data.message);
                                    // window.location.reload();
                                  } else {
                                    toast.error(buyDeal.data.message);
                                  }
                                  setDisablebutton(false);
                                }
                                // setRefresh(!refresh);
                              }}
                              // disabled={ userData?.credit_balance <= TotalCoin || disablebutton }
                              disabled={disablebutton}
                            >
                              buy deals
                            </button>
                            <h5 class="buydealPrice-icon bulkdealsPriceicon">
                              {" "}
                              <GiTwoCoins /> {`${TotalCoin}`} Coins
                            </h5>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </>

      )
    
    }
      <>
        {/* <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel="My dialog"
          className="customdeal-form-modal"
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        > */}
        <CustomdealForm
          isOpen={isOpen}
          toggleModal={toggleModal}
          setIsOpen={setIsOpen}
        />
        {/* </Modal> */}
      </>
    </>
  );
}

export default ProductTab;
