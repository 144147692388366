import React from "react";
// import logo from "../assets/img/logo.png";
import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";

import logo1 from "../assets/img/logo1.png";
import { FiHome } from "react-icons/fi";
import {
  AiOutlineStar,
  AiOutlineShoppingCart,
  AiOutlineUser,
} from "react-icons/ai";
import { BiExtension } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import * as serviceCaller from "../Services/index";
export default function Header() {
  const [category, setCategory] = useState("all");
  const [Plans, setPlans] = useState([]);
  let [sort, setSortValue] = useState("default_sort");
  let [SelectedPlan, setSelectedPlan] = useState("all");
  let [page, setPage] = useState(1);
  let [pagesize, setPagesize] = useState(5);
  let [allDeal, setAllDeal] = useState([]);
  const [userData, setUserData] = useState();
  let [AllCategory, setAllCategory] = useState([]);
  const [Searchdata, setSearchdata] = useState();
  const [refresh, setRefresh] = useState(false);
  const [alldealpagesize, setAlldealpagesize] = useState(10);
  const [searchdealdata, setSearchdealdata] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [coinvalue, setCoinvalue] = useState(50);
  const [pagenumber, setPagenumber] = useState(1);
  const [page_size, setpage_size] = useState(10);

  useEffect(() => {
    getMyAllDeals(page, page_size, sort, "all", category, Searchdata, null);
  }, [
    page,
    sort,
    alldealpagesize,
    SelectedPlan,
    category,
    Searchdata,
    refresh,
  ]);
  const getMyAllDeals = async (
    page = pagenumber,
    page_size = 10,
    sort,
    SelectedPlan = "all",
    category = "",
    Searchdata = "",
    includes = "favorites likes purchases is_not"
  ) => {
    let res = await serviceCaller.getMyDeals({
      page,
      page_size,
      sort,
      SelectedPlan,
      category,
      Searchdata,
      includes,
    });
    setAllDeal(res?.data);
    setSearchdealdata(res?.data?.docs);
  };
  return (
    <>
      <div className="responsiveheader-container">
        <div className="responsiveheader-wrapper">
          <div className="responsiveheader-item">
            <img src={logo1} className="logoresponsiveImage" />
          </div>
          <div className="dealsmp-searchform ressearch-form" style={{display:"none"}}>
                    <input
                      type="text"
                      placeholder="Search Here"
                      onChange={(e) => {
                        setSearchdata(e.target.value);
                    
                      }}
                    />
                    <button>
                      <FiSearch />
                    </button>
                  </div>          <div className="responsive__menuitem">
            <Menu Left>
              <ul className="mobile-menuitems">
                <li className="mobile-menuitem">
                  <NavLink exact activeClassName="active" to="/">
                    {/* <span> */}
                    <FiHome />
                    Home
                    {/* </span> */}
                  </NavLink>
                </li>
                {/* <li className="mobile-menuitem">
             
              <NavLink activeClassName="active" to="/wishlist">
                    <AiOutlineStar />
                  Wishlist
                </NavLink>
            </li> */}

                <li className="mobile-menuitem">
                  <NavLink activeClassName="active" to="/purchased">
                    <AiOutlineShoppingCart />
                    Purchased
                  </NavLink>
                </li>
                <li className="mobile-menuitem">
                  <NavLink activeClassName="active" to="/getcoins">
                    <AiOutlineShoppingCart />
                    Get Coins
                  </NavLink>
                </li>
                {/* <li className="mobile-menuitem">
        
              <NavLink activeClassName="active" to="/extension">
                    <BiExtension />
                  extension
                </NavLink>
            </li> */}
                {/* <li className="mobile-menuitem"> */}

                {/* <a href="/pricing">Pricing</a> */}
                {/* <NavLink activeClassName="active" to="/pricing"> */}
                {/* <span className="sidebar-icon">
                    <AiOutlinePercentage />
                  </span> */}
                {/* pricing
                </NavLink> */}
                {/* </li> */}
                {/* <li className="mobile-menuitem">
                  <NavLink activeClassName="active" to="/learnmore">
                    <BsArrowRight />
                    Learn more
                  </NavLink>
                </li> */}
                <li className="mobile-menuitem">
                  <NavLink activeClassName="active" to="/user">
                    <AiOutlineUser />
                    User
                  </NavLink>
                </li>
              </ul>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}
