import React from "react";
import SiderBar from "../SideBar";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Header from "../Header";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";

import * as serviceCaller from "../../Services/index";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import moment from "moment";
import { Button } from "antd";
import ResetForm from "./resetform";

export default function User() {
  const [keycloak, initialized] = useKeycloak();
  const [isDisabled, setIsdisabled] = useState(true);
  const [alwaysDisabled, setAlwaysDisabled] = useState(true);
  const [userApidata, setUserapidata] = useState([]);
  const [SubscriptionData, setSubscriptionData] = useState([]);
  const [updateuserApidata, setUpdateserapidata] = useState();
  const [plantype, setPlantype] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  // const [message, setMessage] = useState("");

  const [onchange, setOnchange] = useState(true);

  // console.log("errors", errors);
  const formSubmit = async (data) => {
    // console.log("data");
    // console.log("---data--", data);
    // update profile

    let response = await serviceCaller.updateProfile(data);

    // console.log({ response });

    if (response.status == 200) {
      refetch();
      setIsdisabled(true);

      if (response.data.message == "User Info Updated Successfully") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    }
  };

  // fetching wishlistdata
  const { isLoading, error, data, isFetching, refetch } = useQuery(
    "getProfileData",
    async () => {
      // get profile
      await serviceCaller.getProfile().then((res) => {
        // console.log("res.data : ",res?.data?.SubscriptionDataD);
        setUserapidata(res?.data?.UserData);
        setSubscriptionData(res?.data?.SubscriptionDataD);
      });

      // getSubscription
      // await serviceCaller.getSubscription().then((res) => {
      //   // console.log(res.data);
      //   setPlantype(res.data);
      // });
    }
  );

  // console.log("get profile data",data)
    
  // if (isLoading) return "Loading....";

  if (error) return "An error has occurred: " + error.message;

  const handleClick = () => {
    // console.log("button clicked");
    setIsdisabled(false);
  };

  // if (!userApidata.docs) {
  //   return <p>Loading... </p>;
  // }
  const handleLogout = () => {
    keycloak.logout();
  };

  // let subscriptionDatedata = plantype.docs &&
  //   plantype.docs.active_subscriptions[0].created_on;
  // console.log("subscriptionDate",subscriptionDatedata);
  //   let subscriptionDate = new Date(subscriptionDatedata);
  //   console.log("subscriptionDate",subscriptionDate)
  //  let subscription_date = subscriptionDate.toLocaleDateString('en-US');
  //  console.log("subscription_date",subscription_date)

  // console.log("userApidata",userApidata);
  // console.log("moment",moment( userApidata.docs &&  userApidata.docs.created_on).format("L"))

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Helmet>
        <title>User - Amz Deals MarketPlace Dashboard</title>
      </Helmet>
      <Header />
      <div className="container">
        <SiderBar />
        <div className="container-width p-0">
          <div className="extension-header">
            <div className="account-details-wrapper">
              <h3 className="account-title">Account details</h3>
              {/* <div className="min-container-btn pb-3"> */}
              <button className="logout-btn" onClick={handleLogout}>
                logout
              </button>
              {/* </div> */}
            </div>
          </div>
          <div className="min-container">
            <div className="account-details-container pt-3 pb-3">
              {/* <div className="min-container-btn pb-3">
                <button className="logout-btn" onClick={handleLogout}>
                  logout
                </button>
              </div> */}
              <form
                className="account-details-innercontainer pl-5 pr-5 pt-3 pb-4"
                onSubmit={handleSubmit(formSubmit)}
              >
                <div
                //  className="account-details-titlecontainer"
                >
                  {/* <div className="account-details-titlebuttons">
                    <button
                      type="button"
                      className="reset-btn"
                      onClick={toggleModal}
                    >
                      Update Password
                    </button>

                    <button
                      type="button"
                      className="edit-btn"
                      onClick={handleClick}
                    >
                      edit
                    </button>
                  </div> */}
                </div>
                <div className="accountform-wrapper">
                  <div className="accountform-halfwrapper-1">
                    <label className="account_label mb-1">First Name</label>
                    <input
                      type="text"
                      className="Accountform-input"
                      name="first_name"
                      {...register("first_name", { required: true })}
                      disabled={isDisabled}
                      control={control}
                      {...(onchange
                        ? {
                            ...setValue(
                              "first_name",
                              // userApidata.docs && userApidata.docs.first_name
                              userApidata && userApidata.first_name
                            ),
                          }
                        : "")}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setError("first_name");
                          setOnchange(false);
                        } else {
                          setValue("first_name", e.target.value);
                        }
                      }}
                    />

                    <br />
                    {errors.first_name && (
                      <span className="error-message">
                        Please enter First Name
                      </span>
                    )}
                  </div>
                  <div className="accountform-halfwrapper-2">
                    <label className="account_label mb-1">Last Name</label>
                    <input
                      type="text"
                      className="Accountform-input"
                      name="last_name"
                      {...register("last_name", { required: true })}
                      disabled={isDisabled}
                      control={control}
                      {...(onchange
                        ? {
                            ...setValue(
                              "last_name",
                              userApidata && userApidata.last_name
                              // userApidata.docs && userApidata.docs.last_name
                            ),
                          }
                        : "")}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setError("last_name");
                          setOnchange(false);
                        } else {
                          setValue("last_name", e.target.value);
                        }
                      }}
                      // defaultValue={
                      //   userApidata.docs && userApidata.docs.first_name
                      // }
                    />
                    <br />{" "}
                    {errors.last_name && (
                      <span className="error-message">
                        Please enter Last Name
                      </span>
                    )}
                  </div>
                </div>

                <div className="accountform-wrapper">
                  <div className="accountform-halfwrapper-1">
                    <label className="account_label mb-1">Email Id</label>
                    <input
                      type="email"
                      className="Accountform-input"
                      disabled={alwaysDisabled}
                      defaultValue={userApidata && userApidata.email}
                    />
                  </div>
                  {/* <div>
                    <div className="accountform-halfwrapper-2">
                      <label className="account_label mb-1">Password</label>
                      <input
                        type="password"
                        className="Accountform-input"
                        name="password"
                        onChange={(e)=>{
                          setPassword(e?.target?.value);
                        }}    
                        disabled={isDisabled}
                      />
                    </div>
                    {!isDisabled && (
                      <div
                      className="account-details-titlebuttons"
                      >
                      <button
                        type="button"
                        className="edit-btn"
                        onClick={async()=>{
                          let response = await serviceCaller.updatePassword(password);
                          if (response.data.message == "Password Updated Successfully") {
                            toast.success(response.data.message);
                          } else {
                            toast.error(response.data.message);
                          }
                        }}
                      >
                        Update
                      </button>
                      </div>
                    )}
                  </div> */}
                  {/* <div className="accountform-halfwrapper-2">
                    <label className="account_label mb-1">
                      amazon market place
                    </label>
                    <input
                      type="text"
                      className="Accountform-input"
                      name="amazon_marketplaces"
                      {...register("amazon_marketplaces", { required: true })}
                      disabled={isDisabled}
                      control={control}
                      {...(onchange
                        ? {
                            ...setValue(
                              "amazon_marketplaces",
                              userApidata && userApidata.amazon_marketplaces
                              // userApidata.docs && userApidata.docs.amazon_marketplaces
                            ),
                          }
                        : "")}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setError("amazon_marketplaces");
                          setOnchange(false);
                        } else {
                          setValue("amazon_marketplaces", e.target.value);
                        }
                      }}
                    />
                    <br />{" "}
                    {errors.amazon_marketplaces && (
                      <span className="error-message">
                        Please enter Amazon Marketplace
                      </span>
                    )}
                  </div> */}
                </div>

                {/* <div className="accountform-wrapper">
                  <div className="accountform-halfwrapper-1">
                    <label className="account_label mb-1">
                      selling Account display name
                    </label>
                    <input
                      type="text"
                      className="Accountform-input"
                      name="amazon_account_name"
                      {...register("amazon_account_name", { required: true })}
                      disabled={isDisabled}
                      control={control}
                      {...(onchange
                        ? {
                            ...setValue(
                              "amazon_account_name",
                              userApidata && userApidata.amazon_account_name
                              // userApidata.docs && userApidata.docs.amazon_account_name
                            ),
                          }
                        : "")}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setError("amazon_account_name");
                          setOnchange(false);
                        } else {
                          setValue("amazon_account_name", e.target.value);
                        }
                      }}
                    />
                    <br />{" "}
                    {errors.amazon_account_name && (
                      <span className="error-message">
                        Please enter selling Account display name
                      </span>
                    )}
                  </div> */}
                  {/* <div className="accountform-halfwrapper-2">
                    <label className="account_label mb-1">
                      amazon market place
                    </label>
                    <input
                      type="text"
                      className="Accountform-input"
                      name="amazon_marketplaces"
                      {...register("amazon_marketplaces", { required: true })}
                      disabled={isDisabled}
                      control={control}
                      {...(onchange
                        ? {
                            ...setValue(
                              "amazon_marketplaces"
                              // userApidata.docs && userApidata.docs.amazon_marketplaces
                            ),
                          }
                        : "")}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setError("amazon_marketplaces");
                          setOnchange(false);
                        } else {
                          setValue("amazon_marketplaces", e.target.value);
                        }
                      }}
                    />
                    <br />{" "}
                    {errors.amazon_marketplaces && (
                      <span className="error-message">
                        Please enter Amazon Marketplace
                      </span>
                    )}
                  </div> */}
                {/* </div> */}

                {/* <div className="accountform-wrapper">
                  <div className="accountform-halfwrapper-1">
                    <label className="account_label mb-1">Phone No</label>
                   
                   
                   <input
                      type="text"
                      className="Accountform-input"
                      name="phone.value"
                      {...register("phone.value", { required: true,   pattern: {
                        value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                        message: "invalid Phone Please enter a Phone",
                      } })}
                      disabled={isDisabled}
                      control={control}
                      {...(onchange
                        ? {
                            ...setValue(
                              "phone.value",
                              userApidata?.docs ? userApidata?.docs.phone.value : ""
                            ),
                          }
                        : "")}
                      onChange={(e) => {
                        if (!e?.target?.value) {
                          setError("phone.value");
                          setOnchange(false);
                        } else {
                          setValue("phone.value", e?.target?.value);
                        }
                      }}
                    />
                    <br />
                    {errors.phone && (
                      <span className="error-message">
                        {" "}
                        Please enter a Phone
                      </span>
                    )}
                  </div>
                </div> */}

                {/* {isDisabled ? (
                  ""
                ) : ( */}
                <button
                  // onClick={() => }
                  style={isDisabled ? { visibility: "hidden" } : {}}
                  type="submit"
                  className="save-btn"
                >
                  save
                </button>
                {/* )}  */}
                {/* </div> */}
              </form>
            </div>

            <div className="billing-overview-container mt-3 pb-4">
              {/* <div className="account-details-innercontainer pl-5 pr-5 pt-4 pb-4">
                <h3 className="billing-title mb-3">billing overview</h3>
                <div className="subscriptionbill-box">
                  {SubscriptionData &&
                    SubscriptionData.map((item) => (
                      <>
                        <div className="subscription-plantype">
                          <h4>Plan Type</h4>
                          <p>
                            {item &&
                              item?.tranInitPlanFromGateway &&
                              item?.tranInitPlanFromGateway.nickname}
                          </p>
                        </div>

                        <div className="subscription-plantype">
                          <h4>Subscription Start Date</h4>
                          <p>
                            {moment(
                              new Date(
                                item?.tranInitSubscriptionFromGateway
                                  ?.current_period_start * 1000
                              )
                            ).format("DD-MM-YYYY")}
                          </p>
                        </div>
                      </>
                    ))} */}
                  {/* <table className="subscriptionbill-table">
                    <tr className="subscription-table">
                      <td>plan type</td>
                      <td>subscription start date</td>
                      <td>subscription end date</td>
                    </tr>
                    {SubscriptionData &&
                      SubscriptionData.map((item) => (
                        <tr className="subscription-tabledata">
                          <td>
                            {item &&
                              item.tranInitPlanFromGateway &&
                              item.tranInitPlanFromGateway.nickname}
                          </td>
                          <td>
                            {moment(
                              new Date(
                                item?.tranInitSubscriptionFromGateway
                                  ?.current_period_start * 1000
                              )
                            ).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            {moment(
                              new Date(
                                item?.tranInitSubscriptionFromGateway
                                  ?.current_period_end * 1000
                              )
                            ).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                      ))}
                  </table> */}
                {/* </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <ResetForm
        isOpen={isOpen}
        toggleModal={toggleModal}
        isDisabled={isDisabled}
        setIsOpen={setIsOpen}
        keycloak={keycloak}
      />
    </>
  );
}
