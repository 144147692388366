// import  from "react";
import React, { useState, useRef, useEffect } from "react";
// import productimage from '../assets/img/product.jpg'
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Avatar, Image } from "antd";
import { BiLinkAlt } from "react-icons/bi";
import { BsFillCartCheckFill } from "react-icons/bs";
import axios from "axios";
import Keepa from "../Keepa";
import { toast } from "react-toastify";
import * as serviceCaller from "../../Services/index";
import moment from "moment";
import { GiTwoCoins } from "react-icons/gi";
import styled from "styled-components";
let Wrapper = styled.div``;

function Productcard({
  value,
  selectDeal,
  userData,
  refetch,
  activeTab,
  refresh,
  refreshstate,
  TotalDeals,
  coinvalue,
  setCoinvalue,
}) {
  const [oneDay, setOneDay] = useState({});
  const [week, setWeek] = useState({});
  const [visible, setVisible] = useState(false);
  const [day, setDay] = useState(1);
  let [disablebutton, setDisablebutton] = useState(false);
  const [multipledeallist, setMultipledeallist] = useState([]);
  const [showdeal, setShowdeal] = useState(false);
  const [deal, setDeal] = useState(false);
  // const [viewdeal, setViewdeal] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSelectedDeals();
  }, [value.dealStatus]);

  useEffect(() => {
    setDisablebutton(false);
    setShowdeal(undefined);
  }, [value.store_name]);

  let getSelectedDeals = async () => {
    let selecteddeals = await TotalDeals?.docs?.filter((val) => {
      if (val.dealStatus == true) {
        return val;
      }
    });
    setMultipledeallist(selecteddeals);
  };

  // add wishlist

  const handleAddWishlist = async () => {
    let res = await serviceCaller.addWishlist(value);
    if (res.status == 200) {
      refetch();
      setLoading(false);
      if (res.data.message === "Removed Favorites Successfully") {
        toast.error(res.data.message);
      } else {
        toast.success(res.data.message);
      }
      refresh();
    }
  };

  // add purchase
  const handlePurchased = async () => {
    let res = await serviceCaller.addPurchase(value);

    if (res.status == 200) {
      refetch();
      setLoading(false);
      // if (res.data.message === "Removed Purchases Successfully") {
      //   toast.error(res.data.message);
      // } else {
      //   toast.success(res.data.message);
      // }
      refresh();
    }
  };
  // add likes
  const handleLike = async () => {
    setDisablebutton(true);
    let res = await serviceCaller.getLikes(value);
    if (res.status == 200) {
      refetch();
      setLoading(false);
      if (res.data.message === "disLiked Successfully") {
        toast.error(res.data.message);
      } else {
        toast.success(res.data.message);
      }
      refresh();
    }
  };

  //add dislikes
  const handledisLike = async () => {
    setDisablebutton(true);
    let res = await serviceCaller.getDislikes(value);
    if (res.status == 200) {
      refetch();
      setLoading(false);
      if (res.data.message === "Liked Successfully") {
        toast.error(res.data.message);
        // toast.error(" ")
      } else {
        toast.success(res.data.message);
      }
      refresh();
    }
  };

  useEffect(() => {
    setVisible(false);
  }, [value]);

  const keepa = async (data) => {
    let baseUrl = `https://api.keepa.com/product`;

    let dayResponse = await axios.get(baseUrl, {
      params: {
        key: "8jbces27j86mqkijcfeb1vtrgh75eh21r0f0t6dbf16mf1ok2jd7vhmnn4e9nbrt",
        domain: 1,
        asin: data,
        days: 1,
      },
    });

    // let monthResponse = await axios.get(baseUrl, {
    //   params: {
    //     key: "8jbces27j86mqkijcfeb1vtrgh75eh21r0f0t6dbf16mf1ok2jd7vhmnn4e9nbrt",
    //     domain: 1,
    //     asin: data,
    //     days: 30,
    //   },
    // });

    let weekResponse = await axios.get(baseUrl, {
      params: {
        key: "8jbces27j86mqkijcfeb1vtrgh75eh21r0f0t6dbf16mf1ok2jd7vhmnn4e9nbrt",
        domain: 1,
        asin: data,
        days: 7,
      },
    });
    // let SixtyDayResponse = await axios.get(baseUrl, {
    //   params: {
    //     key: "8jbces27j86mqkijcfeb1vtrgh75eh21r0f0t6dbf16mf1ok2jd7vhmnn4e9nbrt",
    //     domain: 1,
    //     asin: asin,
    //     days: 60,
    //   },
    // });
    setOneDay(dayResponse.data);
    // setMonth(monthResponse.data);
    setWeek(weekResponse.data);
    // setSixtyDay(SixtyDayResponse.data);

    // const tracking = dayResponse.data.products[0].trackingSince;

    // const date = moment
    //   .utc((tracking + 21564000) * 60000)
    //   .set({ second: 0, millisecond: 0 })
    //   .toDate();

    // console.log("dates", date);
  };

  const handleGraph = (e) => {
    setVisible((prevState) => !prevState);
    keepa(e.target.dataset.asin);
    // setAsin(e.target.dataset.asin);
  };

  const past24hrs = moment.utc().subtract(24, "hours").valueOf();

  // console.log("past24hrs", past24hrs);

  const past72hrs = moment.utc().subtract(72, "hours").valueOf();

  const handleshowdeal = async (value) => {
    setDisablebutton(true);
    try {
      let buyDeal = await serviceCaller.buyDeal(value);
      // console.log("buyDeal : ", buyDeal);
      // setDisablebutton(false);
      if (buyDeal.status == 200) {
        refresh();
        // refetch();
        setLoading(false);
        if (buyDeal.data.message === "Deal Purchased") {
          toast.success(buyDeal.data.message);
        } else {
          toast.error(buyDeal.data.message);
        }
      }
    } catch (error) {
      console.log("buyDeal error : ", error);
    }
  };

  // console.log("coinvalue",coinvalue)
  // console.log("showdeal",showdeal)

  const multipleDealbuy = () => {
    setCoinvalue(coinvalue - multipledeallist?.length);
    // setViewdeal(true);
    // value.dealStatus = false;
  };

  const handlesViewdeal = (value) => {
    setShowdeal(!showdeal);
    // console.log("handlesViewdeal", value);
  };

  // console.log("userData",userData);

  return (
    <>
      <div className="productcard desktop-productcard">
        {/* <div className="product-container mt-2 "> */}
        <div className="product-dealmp-containter pt-2 pb-2 pr-2 pl-2">
          <div className="product-dealmp-checkbox">
            {!value?.asin ? (
              <input
                type="checkbox"
                className="dealsmp-checkbox"
                checked={value?.dealStatus}
                onChange={(e) => {
                  selectDeal(value);
                  value.dealStatus = e?.target?.checked;
                }}
              />
            ) : (
              <>
                <input
                  disabled
                  style={{ cursor: "not-allowed" }}
                  type="checkbox"
                  className="dealsmp-checkbox"
                  checked={value?.dealStatus}
                />
              </>
            )}
          </div>
          {/* <div className="product-details "> */}

          <div className="product-heading">
            <table>
              {/* <tr>
              <th>Store Name</th>
              <th>Category</th>
              <th>BSR Rank</th>
              <th>Profit</th>
              <th>ROI</th>
              <th>Country</th>
              <th>Created on</th>
              <th style={{ visibility: "hidden" }}>...</th>
            </tr> */}
              <tr></tr>
              <tr>
                <td>{value.store_name}</td>
                <td>
                  {value?.cat_lists[0]?.name
                    ? value?.cat_lists[0]?.name
                    : "Uncategorized"}
                </td>
                <td>{`${value.ninety_days_rank ?? 0}`}</td>
                <td>${value.net_profit}</td>
                <td>{value.roi}%</td>
                <td>{value.country_name}</td>
                <td>{moment(value.created_on).format("L")}</td>
                <td class="buys-dealbtn">
                  {value?.asin ? (
                    <button
                      className="graph-btn"
                      onClick={() => handlesViewdeal(value)}
                    >
                      {`   `}view deal
                    </button>
                  ) : (
                    <>
                      <button
                        className={"amazon-btn dis-block"}
                        onClick={() => {
                          // if(!disablebutton){
                          //   setDisablebutton(true);
                          handleshowdeal(value);
                          // }
                        }}
                        // disabled={ userData?.credit_balance < value?.deal_price || disablebutton }
                        disabled={disablebutton}

                        // style={{ marginLeft: "23px" }}
                      >
                        buy deal
                      </button>
                      <h5 class="buydealPrice-icon">
                        {" "}
                        <GiTwoCoins />
                        {`${value?.deal_price ?? 0} `} Coins
                      </h5>
                    </>
                  )}
                </td>
              </tr>
            </table>

            {/* <h4 className="product-title pt-1 pb-1 storename-val"> </h4>

            <h5 className=" pt-1 pb-1 category-val"></h5>

            <h5 className="h5-productdmp pt-1 pb-1 bsr-val"></h5>

            <h5 className="profit-val"></h5>
            <h5 className="roi-val"></h5>

            <h5 className="countryname-val"></h5>
            <h5 className="pt-1 pb-1 date-val"></h5> */}
          </div>

          {/* </div> */}

          <div></div>
        </div>
        <div
          className={
            showdeal
              ? "product-info mt-1 showdeal"
              : "product-info mt-1 hidedeal"
          }
        >
          <div className="product-table-info mt-2 pl-4 mr-3">
            <div className="product-table pt-2">
              <div className="product-table-list-1">
                <h4 className=" product-table-value "> Product Name </h4>
                <h5 className="product-table-title productname">
                  {" "}
                  {value.name}
                </h5>
                <h4 className="product-table-value product-table-custom">
                  Net Profit{" "}
                </h4>
                <h5 className=" product-table-title">
                  ${Number(value.net_profit)}
                </h5>
              </div>

              <div className="product-table-list-2">
                <h4 className="product-table-value "> Store Price </h4>
                <h5 className="product-table-title">
                  ${Number(value.store_price)}
                </h5>

                <h4 className=" product-table-value product-table-custom">
                  {" "}
                  ROI
                </h4>
                <h5 className="product-table-title"> {Number(value.roi)}%</h5>
              </div>

              <div className="product-table-list-3">
                <h4 className="product-table-value"> Amazon Price </h4>
                <h5 className="product-table-title">
                  ${Number(value.amazon_price)}
                </h5>

                <h4 className=" product-table-value product-table-custom">
                  ASIN{" "}
                  <span className="asinlink-icon">
                    {" "}
                    <BiLinkAlt />
                  </span>
                </h4>
                <a
                  target="_blank"
                  href={value.amazon_product_url}
                  style={{ textDecoration: "underline" }}
                >
                  <h5 className="product-table-title ">{value.asin}</h5>
                </a>
              </div>
              <div className="product-table-list-4">
                <h4 className=" product-table-value">Sellers Count</h4>
                <h5 className="product-table-title">
                  {" "}
                  {Number(value.sellers_count)}
                </h5>

                <h4 className=" product-table-value product-table-custom">
                  Store{" "}
                  <span className="asinlink-icon">
                    {" "}
                    <BiLinkAlt />
                  </span>
                </h4>
                <a
                  target="_blank"
                  href={value.store_product_url}
                  style={{ textDecoration: "underline" }}
                >
                  <h5 className="product-table-title">{value.store_name}</h5>
                </a>
              </div>
            </div>
            {/* <hr className="rulerline" /> */}
          </div>
        </div>
      </div>

      <div className="mobile-productcard">
        <div className="mobile-top-wrapper">
          <div className="mobile-storename-wrapper">
            <span className="mobile-checkbox-container">
              <span className="mobile-checkbox-wrapper">
                {!value?.asin ? (
                  <input
                    type="checkbox"
                    className="dealsmp-checkbox"
                    checked={value?.dealStatus}
                    onChange={(e) => {
                      selectDeal(value);
                      value.dealStatus = e?.target?.checked;
                    }}
                  />
                ) : (
                  <>
                    <input
                      disabled
                      style={{ cursor: "not-allowed" }}
                      type="checkbox"
                      className="dealsmp-checkbox"
                      checked={value?.dealStatus}
                    />
                  </>
                )}
              </span>
              <h4>{value.store_name}</h4>
            </span>
            <h5 className="mobile-category-wrapper">
              {value?.cat_lists[0]?.name
                ? value?.cat_lists[0]?.name
                : "Uncategorized"}
            </h5>

            <h5 className="mobile-category-wrapper">
              Created on :<span>{moment(value.created_on).format("L")}</span>
            </h5>
          </div>
          <div className="mobile-button-wrapper">
            {value?.asin ? (
              <button
                className="graph-btn"
                onClick={() => handlesViewdeal(value)}
              >
                {`   `}view deal
              </button>
            ) : (
              <>
                <button
                  className={"buydeal-btn dis-block"}
                  onClick={() => {
                    // if(!disablebutton){
                    //   setDisablebutton(true);
                    handleshowdeal(value);
                    // }
                  }}
                  // disabled={ userData?.credit_balance < value?.deal_price || disablebutton }
                  disabled={disablebutton}

                  // style={{ marginLeft: "23px" }}
                >
                  buy deal
                </button>
                <h5 class="buydealPrice-icon">
                  {" "}
                  <GiTwoCoins />
                  {`${value?.deal_price ?? 0} `} Coins
                </h5>
              </>
            )}
          </div>
        </div>
        <div className="mobile-product-wrapper">
          <div className="mobile-productcard-tile">
            <h4>BSR Rank</h4>
            <h5>{`${value.ninety_days_rank ?? 0}`}</h5>
          </div>
          <div className="mobile-productcard-tile">
            <h4>Profit</h4>
            <h5>${value.net_profit}</h5>
          </div>
          <div className="mobile-productcard-tile">
            <h4>ROI</h4>
            <h5>{value.roi}%</h5>
          </div>

          <div className="mobile-productcard-tile">
            <h4>Country</h4>
            <h5>{value.country_name}</h5>
          </div>
        </div>
        <div
          className={
            showdeal
              ? "product-info mt-1 showdeal"
              : "product-info mt-1 hidedeal"
          }
        >
          <div className="product-table-info mt-2 pl-4 mr-3">
            <div className="product-table pt-2">
              <div className="product-table-list-1">
                <h4 className=" product-table-value "> Product Name </h4>
                <h5 className="product-table-title productname">
                  {" "}
                  {value.name}
                </h5>
                <h4 className="product-table-value product-table-custom">
                  Net Profit{" "}
                </h4>
                <h5 className=" product-table-title">
                  ${Number(value.net_profit)}
                </h5>
              </div>

              <div className="product-table-list-2">
                <h4 className="product-table-value "> Store Price </h4>
                <h5 className="product-table-title">
                  ${Number(value.store_price)}
                </h5>

                <h4 className=" product-table-value product-table-custom mobile-productcard-roi">
                  {" "}
                  ROI
                </h4>
                <h5 className="product-table-title mobile-productcard-roi-h5">
                  {" "}
                  {Number(value.roi)}%
                </h5>
              </div>

              <div className="product-table-list-3 mobile-productcard-list-3">
                <h4 className="product-table-value"> Amazon Price </h4>
                <h5 className="product-table-title">
                  ${Number(value.amazon_price)}
                </h5>

                <h4 className=" product-table-value product-table-custom">
                  ASIN{" "}
                  <span className="asinlink-icon">
                    {" "}
                    <BiLinkAlt />
                  </span>
                </h4>
                <a
                  target="_blank"
                  href={value.amazon_product_url}
                  style={{ textDecoration: "underline" }}
                >
                  <h5 className="product-table-title ">{value.asin}</h5>
                </a>
              </div>
              <div className="product-table-list-4 mobile-productcard-list-4">
                <h4 className=" product-table-value">Sellers Count</h4>
                <h5 className="product-table-title">
                  {" "}
                  {Number(value.sellers_count)}
                </h5>

                <h4 className=" product-table-value product-table-custom">
                  Store{" "}
                  <span className="asinlink-icon">
                    {" "}
                    <BiLinkAlt />
                  </span>
                </h4>
                <a
                  target="_blank"
                  href={value.store_product_url}
                  style={{ textDecoration: "underline" }}
                >
                  <h5 className="product-table-title">{value.store_name}</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Productcard;
