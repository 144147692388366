import React from "react";

function ErrorPage(){
    return(
    <>
  
   
  <div className="error-container">
        <div>
        <img src="/img/AOA-404-page.png" alt="errorimage"/>
        </div>
<div>
        <p className="error-name">
        404 <span>Error
        </span>
        </p>

<p className="error-para">
Page Doesn't exist or some error occured. Go back to our 
<a href="/">Homepage
</a>
</p>

</div>
  </div>
  
  <div>
      <p className="error-heading">Don't worry you will back on track in no time!!</p>
  </div>

    </>);

}

export default ErrorPage;