import React from "react";
import InputRange from "react-input-range";
import { useState } from "react";
import 'react-input-range/lib/css/index.css';




export default function Coinrange({val,setVal}){



    return (
      <form className="coin-slider-form">
        <InputRange
          maxValue={1000}
          minValue={15}
          value={val}
          onChange={val => setVal(val)}
          // onChangeComplete={value => console.log("val",value)}
           />

       
      </form>
    );
  }

// export default Coinrange;