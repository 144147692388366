import React from "react";
import { FaRegSmileWink } from "react-icons/fa";
import Giftbox from "../../assets/img/giftbox.png";
export default function Thankyou() {
  if (window?.location?.pathname?.includes("/thank-you")) {
    setTimeout(() => {
      window.location.replace("https://deals.amzonlinearbitrage.com");
    }, 3000);
  }
  return (
    <>
      <div className="thankyou-page-container">
        {/* <div> */}
        <img src={Giftbox} className="giftbox-image" />
        <div className="thank-text-container">
          <h3 className="thank-text-wrapper mb-2">
            Thank y
            <span className="smile-icon">
              <FaRegSmileWink />
            </span>
            u !
          </h3>
          <p className="mb-2 thank-text-wrapper-2">
            If any queries, please feel free to email us -
            contact@amzonlinearbitrage.com
          </p>
          <p className="mb-2 thank-text-wrapper-2">
            Earn More with Our High Profitable Deals!
          </p>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}
