import { useLottie } from "lottie-react";
import loading from "../loader.json";
import React from "react";
const Loader = () => {
  // let [state,setState] = useState();

  const style = {
    height: 100,
    width: 100,
    //   textAlign:"center",
    //   padding:"30%"
  };

  const options = {
    animationData: JSON.parse(loading),
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, style);

  return (
    // <div style={{ textAlign: "center", padding: "20%", paddingLeft: "45%" }}>
    <div style={{ height:"100vh",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
      {View}
    </div>
  );
};
export default Loader;
