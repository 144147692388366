import React, { useState, useEffect } from "react";
import SiderBar from "../SideBar";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import Header from "../Header";
import { Helmet } from "react-helmet";
import Coinrange from "./coinrange";
import * as serviceCaller from "../../Services/index";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useForm } from "react-hook-form";
import { BsArrowRight } from "react-icons/bs";
import { getUserPlan } from "../../Services/index";
import Loader from "../Loader";

function Getcoins() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [val, setVal] = useState(15);

  const [credit, setCredit] = useState(20);
  // const [val1,setVal1] =useState();
  const [userData, setUserData] = useState();
  const [dealsData, setDealsData] = useState();
  // const [data, setData] = useState("");

  const [plan, setPlan] = useState("");

  const [planType, setPlanType] = useState();

  const [planPeriod, setPlanPeriod] = useState();
  const [loader, Setloader] = useState(false);
  const [PlanStatus, setPlanStatus] = useState();
  let [stripe, setStripe] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getUser();
    getStripe();
  }, []);

  const getUser = async () => {
    const response = await serviceCaller.getProfile();
    // console.log({ response });
    setUserData(response?.data?.UserData);
    setDealsData(response?.data?.Deals);
  };
  const getStripe = async () => {
    // stripe = await loadStripe("pk_test_mNht0Adwk6YPPk403BH1oEJe");
    stripe = await loadStripe("pk_live_4RacLYfRRDMoEOobK9APDMxl");
    setStripe(stripe);
  };
  const addPayment = async ({ dollarvalue }) => {
    setVal(dollarvalue);
    const values = {
      plan_price: Number(val),
      success_url: "https://deals.amzonlinearbitrage.com/thank-you",

      // success_url: "http://localhost:3000/api/v1/thank-you",
      cancel_url: "https://deals.amzonlinearbitrage.com/getcoins",
      // cancel_url: "http://localhost:3000/api/v1/getcoins",
    };
    // console.log("val-getcoins", values);
    if (val >= 15) {
      // Setloader(true);

      try {
        values.keycloak_id = localStorage.getItem("keycloak_id");
        values.keycloak_email = localStorage.getItem("keycloak_email");
        console.log("/stripe", values);
        const res = await axios.post(
          // `http://localhost:3003/api/v1/stripe`,
          `https://dealsbackend.amzonlinearbitrage.com/api/v1/stripe`,
          values
        );
          console.log("res",res)
        if (!res?.data?.docs) {
          setPlanStatus(res?.data?.message);
          Setloader(false);

          // reset();
        } else {
          if (res.status === 200) {
            // setPlanStatus(undefined);
            setMessage(res.data);
            Setloader(true);
            if (res?.data?.docs?.id) {
              await stripe.redirectToCheckout({
                sessionId: res?.data?.docs?.id,
              });
            } else {
              // reset();
            }
          }
        }
      } catch (err) {
        console.log("err", err);
      }
      Setloader(false);
    }
    setCredit(credit + val * 3);
  };
  return (
    <>
      {loader ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Helmet>
            <title>GetCoin - Amz Deals MarketPlace Dashboard</title>
          </Helmet>
          <Header />

          <div className="container">
            <SiderBar />

            <div className="container-width">
              <div className="min-container">
                <div className="learnmore-container pt-4 pr-6 pl-6 pb-4 mt-2">
                  <div className="learnmore-wrapper">
                    <div className="addcredit-wrapper">
                      <h4>Your coin balance</h4>
                      <h2>{userData?.credit_balance}</h2>
                      <a href="/">
                        {" "}
                        <span className="sidebar-icon">
                          <BsArrowRight />
                        </span>
                        View Deals
                        {/* <button className="Get-a-Deal">Get a Deal</button> */}
                      </a>
                    </div>

                    <div className="avalaible-deals-wrapper">
                      <h4>Available Deals </h4>

                      <h2>{dealsData?.length}</h2>
                      {/* <p>deals avalaible</p> */}
                      <a href="/">
                        {" "}
                        <span className="sidebar-icon">
                          <BsArrowRight />
                        </span>
                        View Deals
                        {/* <button className="Get-a-Deal">Get a Deal</button> */}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="min-container">
                <h2 className="credit-title">Add your coins here</h2>

                <div className="coin-slider-container">
                  <Coinrange val={val} setVal={setVal} />
                  <div className="confirm-btn-container">
                    <h3>selected coins = {val * 3}</h3>
                    <div class="coin-price-total-wrapper">
                      <form onSubmit={handleSubmit(addPayment)}>
                        <h3>$</h3>
                        <input
                          type="number"
                          value={val}
                          defaultValue={val}
                          {...register(
                            "dollarvalue",

                            { required: true, maxLength: 4, minLength: 2 }
                          )}
                          placeholder="enter your amount"
                          onChange={(e) => setVal(e.target.value)}
                          className="dollar-input"
                        />

                        <button className="graph-btn buy-nowbtn" type="submit">
                          buy now
                        </button>
                      </form>
                      {errors.dollarvalue ||
                        (val < 15 && (
                          <p className="coin-error-message">
                            Only available for purchases over $15
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Getcoins;
