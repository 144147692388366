import React from "react";
import { useQuery } from "react-query";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useState } from "react";
import * as serviceCaller from "../../Services/index";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";

export default function ResetForm({isOpen,toggleModal,isDisabled,setIsOpen,keycloak}){

  const [password, setPassword] = useState();
    

  const {
    register,
    handleSubmit,
    setValue,control ,
    reset,
    setError,
    formState: { errors },
  } = useForm();

    const handleUpdatepassword =async({password})=>{
        let response = await serviceCaller.updatePassword(password);
        if (response.data.message == "Password Updated Successfully") {

            reset();
            setIsOpen(!isOpen)
          toast.success(response.data.message);
          keycloak.logout();
        } else {
            reset();
            setIsOpen(!isOpen)
          toast.error(response.data.message);
        }   
    }


    return(<>
        <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        {/* <div>My modal dialog.</div>
        <button onClick={toggleModal}>Close modal</button> */}
           <form onSubmit={handleSubmit(handleUpdatepassword)}>
                    <div className="accountform-halfwrapper-2">
                      <label className="account_label mb-1"> Please Enter Your Password</label>
                      <input
                        type="password"
                        className="Accountform-resetinput"
                        name="password"
                        onChange={(e)=>{
                          setPassword(e?.target?.value);
                        }}    
                     
                        {...register("password",{required:true})}
                      />
                        <br />{" "}
                    {errors.password && (
                      <span className="error-message">
                        {/* Please enter Last Name */}
                        Please enter something
                      </span>
                    )}
                    </div>
                    {/* {!isDisabled && ( */}
                      <div
                      className="account-details-resetbuttons"
                      >
                      <button
                        type="submit"
                        className="update--btn"
                        // onClick={
                        //     async()=>{
                        //   let response = await serviceCaller.updatePassword(password);
                        //   if (response.data.message == "Password Updated Successfully") {
                        //     toast.success(response.data.message);
                        //   } else {
                        //     toast.error(response.data.message);
                        //   }
                        // }}
                      >
                        Update
                      </button>
                      </div>
                    {/* )} */}
                  </form>
     
      </Modal>
    </>);
}