const axios = require("axios");
const { useQuery } = require("react-query");
// const { jsonToQueryString } = require("../utils/jsonToQueryString");
const keycloak = require("../keycloak");
const baseURL = process.env.REACT_APP_BASEURL;
const baseURLV1 = process.env.REACT_APP_BASEURL_V1;

// const baseURL = "http://localhost:3003/api/v1/";
// const baseURLV1 = "http://localhost:3003/api/v1/";

// const baseURL = "https://dealsbackend.amzonlinearbitrage.com/api/v1/";

// all deal
export async function getAllDeals({
  page = 1,
  page_size = 20,
  deal_type = "all",
  name,
  sortBy = "-_id",
  category_id = "",
  plan_type,
  list_id,
  includes,
}) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  // console.log("___MAXK>><<", sortBy)
  // console.log("setvi cat",category_id)

  return await axios.get(`${baseURL}deals`, {
    headers: headers,
    params: {
      name,
      page,
      page_size,
      deal_type,
      sort: sortBy,
      category_id,
      plan_type,
      list_id,
      includes,
    },
  });
}

// today deal
export async function getMyDeals({
  page = 1,
  page_size = 20,
  sort = "_id",
  SelectedPlan,
  category = null,
  Searchdata = null,
  includes = "favorites likes purchases is_not",
}) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return await axios.get(`${baseURL}deals/all`, {
    headers: headers,
    params: {
      page,
      page_size,
      sort,
      SelectedPlan,
      category,
      Searchdata,
      includes,
      keycloak_id: localStorage.getItem("keycloak_id"),
    },
  });
}
// today deal
export async function getMyRequestedDeals({
  page = 1,
  page_size = 20,
  sort = "_id",
  SelectedPlan,
  category = null,
  Searchdata = null,
  includes = "favorites likes purchases is_not",
}) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return await axios.get(`${baseURL}deals/my-request`, {
    headers: headers,
    params: {
      page,
      page_size,
      sort,
      SelectedPlan,
      category,
      Searchdata,
      includes,
      keycloak_id: localStorage.getItem("keycloak_id"),
    },
  });
}

// today deal
export async function getMyCategory() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return await axios.get(`${baseURL}deals/get-my/category`, {
    headers: headers,
    params: {},
  });
}

export async function getMyWishlistCategory() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return await axios.get(`${baseURL}deals/get-my/wishlist/category`, {
    headers: headers,
    params: {},
  });
}

export async function getMyPurchasedCategory() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return await axios.get(`${baseURL}deals/get-my/purchased/category`, {
    headers: headers,
    params: {
      keycloak_id: localStorage.getItem("keycloak_id"),
    },
  });
}

// today deal
export async function getMytodayDeals({
  page = 1,
  page_size = 20,
  deal_type = "today",
  sortBy = "-_id",
  category_id = "",
  name,
  plan_type,
  list_id,
  includes,
  SelectedPlan,
  category,
  Searchdata,
}) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  // console.log("___MAXK>><<", sortBy)
  // console.log("setvi cat",category_id)

  return await axios.get(`${baseURL}deals/get-my/today-deals`, {
    headers: headers,
    params: {
      page,
      page_size,
      deal_type,
      sort: sortBy,
      category_id,
      name,
      plan_type,
      list_id,
      includes,
      SelectedPlan,
      category,
      Searchdata,
    },
  });
}

// today deal
export async function todayDeals({
  page = 1,
  page_size = 20,
  deal_type = "today",
  sortBy = "-_id",
  category_id = "",
  name,
  plan_type,
  list_id,
  includes = "favorites likes purchases is_not",
}) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  // console.log("___MAXK>><<", sortBy)
  // console.log("setvi cat",category_id)

  return await axios.get(`${baseURL}deals/my-deals`, {
    headers: headers,
    params: {
      page,
      page_size,
      deal_type,
      sort: sortBy,
      category_id,
      name,
      plan_type,
      list_id,
      includes,
    },
  });
}

export async function getTodayDeals({
  page_size = 20,
  page = 1,
  deal_type = "today",
}) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  // console.log("___MAXK>><<", sortBy)
  // console.log("setvi cat",category_id)

  return await axios.get(`${baseURL}deals`, {
    headers: headers,
    params: {
      page,
      page_size,
      deal_type,
      // sort: sortBy,
      // category_id,
      // name,
      // plan_type,
      // list_id,
      // includes,
    },
  });
}
// get likes

export async function getLikes(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.post(`${baseURL}deals/${value._id}/likes`, value, {
    headers: headers,
  });
}

// get dislikes

export async function getDislikes(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.post(`${baseURL}deals/${value._id}/dislikes`, value, {
    headers: headers,
  });
}

// add and remove wishlist
export function addWishlist(
  value,
  filter = {},
  page = 1,
  page_size = 20,
  deal_type = "all",
  sort = ["-_id"]
) {
  // console.log(value)
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  value.token1 = keycloak.default.token;
  return axios.post(`${baseURL}deals/${value._id}/favorites`, value, {
    headers: headers,
    params: {
      // ...filter,
      page,
      page_size,
      deal_type,
      // sort,
    },
  });
}

// add and remove purchaselist
export function addPurchase(
  value,
  filter = {},
  page = 1,
  page_size = 20,
  deal_type = "all",
  sort = ["-_id"]
) {
  // console.log(value)
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURL}deals/${value._id}/purchases`, value, {
    headers: headers,
    params: {
      // ...filter,
      page,
      page_size,
      deal_type,
      // sort,
    },
  });
}

// display wishlist data

export function getWishlist(
  page,
  page_size,
  category_id,
  includes
  // ...filters
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  // let headers = {
  //   "Content-Type": "application/json",
  //   Authorization: "Bearer " + localStorage.getItem("user_id"),
  // };
  return axios.get(`${baseURL}deals/favorites`, {
    headers: headers,
    params: {
      page,
      page_size,
      category_id,
      includes,
    },
  });
}

// get purchase data
export function getPurchased(
  page,
  page_size,
  category_id,
  Searchdata = null,
  includes
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURL}deals/purchases`, {
    headers: headers,
    params: {
      keycloak_id: localStorage.getItem("keycloak_id"),
      page,
      page_size,
      category_id,
      Searchdata,
      includes,
    },
  });
}

// get Categories data
export function getCategory(
  // filter = {},
  // category_id=category_id,
  page = 1,
  page_size = 20,
  deal_type = "all"
  // sort = ["-_id"]
  // id=[_id],
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURL}categories`, {
    headers: headers,
    params: {
      // ...filter,
      // id:id,
      // id:category_id,
      page,
      page_size,
      deal_type,
      // sort,
    },
  });
}

// get Categories data
export function getUserPlan() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURL}deals/user/plan`, {
    headers: headers,
    params: {},
  });
}

// get profile data
export async function getProfile() {
  console.log("service-caller-keycloak", keycloak);
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.get(`${baseURL}users/profile`, {
    headers: headers,
    params: {
      // keycloak_id : localStorage.getItem("keycloak_id"),
      keycloak_id: keycloak?.default.idTokenParsed?.sub,
    },
  });
}

// get update profile data
export async function UpdateUser(id, data) {
  console.log("UpdateUser -- keycloak",keycloak)
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.put(`${baseURL}users/profile/${id}`, data, {
    headers: headers,
    params: {
      // keycloak_id: localStorage.getItem("keycloak_id"),
      keycloak_id: keycloak?.idTokenParsed?.sub,
    },
  });
}
// update profile data
export async function updateProfile(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.put(`${baseURL}users/profile`, data, {
    headers: headers,
  });
}

export async function updatePassword(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  return await axios.put(
    `${baseURL}users/update_password`,
    { data },
    {
      headers: headers,
    }
  );
}

// get subscription  data
export function getSubscription(
  filter = {},
  // page = 1,
  // page_size = 20,
  deal_type = "all"
  // sort = ["-_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURL}users/subscription`, {
    headers: headers,
    params: {
      ...filter,
      // page,
      // page_size,
      deal_type,
      // sort,
    },
  });
}

// ${baseURL}deals/${value._id}/likes`

export async function addDollar(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  value.token = localStorage.getItem("login_token");

  return await axios.post(`${baseURL}stripe`, value, {
    headers: headers,
  });
}

export async function buyDeal(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  value.keycloak_id = localStorage.getItem("keycloak_id");

  return await axios.post(`${baseURL}deals/buy`, value, {
    headers: headers,
  });
}

export async function buyBulkDeal(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  const Data = {
    data: value,
    keycloak_id: localStorage.getItem("keycloak_id"),
  };
  return await axios.post(`${baseURL}deals/buy/bulk`, Data, {
    headers: headers,
  });
}

//add customdeal

export async function addCustomdeal(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };
  const Data = {
    data: { ...data },
    keycloak_id: localStorage.getItem("keycloak_id"),
  };
  // console.log("data 123 : ",data);
  return await axios.post(`${baseURL}deals/cusomer/request`, Data, {
    headers: headers,
  });
}

// get Categories data
export function getAllCategory() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURL}categories/getall/category`, {
    headers: headers,
    params: {},
  });
}

// post user

export async function Postuser(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.post(`${baseURL}/users`, value, {
    headers: headers,
  });
}
