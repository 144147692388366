import React from "react";
import axios from "axios";
import Home from "./components/Home";
import User from "./components/User";
import { useEffect, useState } from "react";
import Extension from "./components/Extension";
import Learnmore from "./components/Getcoins";
import Wishlist from "./components/Wishlist";
import Purchased from "./components/Purchased";
import Getcoins from  "./components/Getcoins"
import Thankyou from "./components/Thankyou";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Pricing from "./components/Pricing";
import { useKeycloak } from "@react-keycloak/web";
import { ToastContainer } from "react-toastify";
import ErrorPage from "./components/404";

function App() {
  const [isUpdated, setIsUpdated] = useState(false);
  const [keycloak, initialized] = useKeycloak();
  useEffect(()=>{
    console.log("keycloak.authenticated : ",keycloak.authenticated);
    if (initialized && keycloak.authenticated) {
      // let updateUserDetails=
       updateUser();
    }
  },[]);
  const updateUser=async()=>{
    const value={
      keycloak:keycloak?.idTokenParsed,
      keycloak_Token: keycloak?.idToken,
    }
    console.log({keycloak});
    localStorage.setItem("keycloak_Token", keycloak?.idToken);
    localStorage.setItem("keycloak_id", keycloak?.idTokenParsed?.sub);
    localStorage.setItem("keycloak_email", keycloak?.idTokenParsed?.email);
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + keycloak?.idToken,
    };
    const response= await axios.post(`https://dealsbackend.amzonlinearbitrage.com/api/v1/users`, value, {
      // const response= await axios.post(`http://localhost:3003/api/v1/users`, value, {
     
    headers: headers,
    });
    console.log("app.js response",response)
    return response;
  }



  if (initialized && keycloak.authenticated) {
    // if (isUpdated && initialized && keycloak.authenticated) {
    // if (!keycloak.realmAccess.roles.some((e) => e == "app-admin")) {
    return (
      <>
        {/* <Router> */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/purchased" element={<Purchased />} />
            {/* <Route path="/pricing" element={<Pricing />} /> */}

            <Route path="/user" element={<User />} />
            {/* <Route path="/extension" element={<Extension />} /> */}

            <Route path="/getcoins" element={<Getcoins />} />
         <Route path="/thank-you" element={<Thankyou/>}/>   
            
            <Route path="*" element={<ErrorPage />} />

          </Routes>
        {/* </Router> */}
      </BrowserRouter>
        <ToastContainer
          position="bottom-left"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
        />
      </>
    );
    // }
  } else {
    keycloak.login();
  }
}

export default App;
