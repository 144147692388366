import React from "react";
// import productimage from "../assets/img/product.jpg";
import { BiLinkAlt } from "react-icons/bi";
import { BsFillCartCheckFill } from "react-icons/bs";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaShoppingCart } from "react-icons/fa";
import { MdUpdate } from "react-icons/md";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import * as serviceCaller from "../Services/index";
import { TiDelete } from "react-icons/ti";
import { useLocation } from "react-router-dom";
import moment from "moment";

function Wishlistcard({ value, dataApi, Refresh }) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showdeal, setShowdeal] = useState(false);
  const [deal, setDeal] = useState(false);
  const [viewdeal, setViewdeal] = useState(false);

  const handleAddWishlist = async () => {
    let res = await serviceCaller.addWishlist(value);

    if (res.status == 200) {
      setLoading(false);
      if (res.data.message === "Removed Favorites Successfully") {
        toast.error(res.data.message);
      } else {
        toast.success(res.data.message);
      }
      Refresh();
    }
  };

  // add purchase
  const handlePurchased = async () => {
    let res = await serviceCaller.addPurchase(value);
    if (res.status == 200) {
      Refresh();
      setLoading(false);
      if (res.data.message === "Removed Purchases Successfully") {
        toast.error(res.data.message);
      } else {
        toast.success(res.data.message);
      }
    }
  };

  const past24hrs = moment.utc().subtract(24, "hours").valueOf();

  // console.log("past24hrs", past24hrs);

  const past72hrs = moment.utc().subtract(72, "hours").valueOf();

  // console.log("past72hrs=", past72hrs);

  // console.log("3days before = ",new Date(past72hrs).toLocaleDateString('en-US'));

  const handlesViewdeal = (value) => {
    setShowdeal(!showdeal);
  };

  return (
    <div className="productcard">
      {/* <div className="product-container mt-2 "> */}
      <div className="product-dealmp-containter pt-2 pb-2 pr-2 pl-2">
        <div className="product-dealmp-checkbox">
          {/* {!value?.asin ? ( */}
          <input
            style={{ visibility: "hidden" }}
            type="checkbox"
            className="dealsmp-checkbox"
            checked={value?.dealStatus}
            // onChange={(e) => {
            //   selectDeal(value?._id);
            //   value.dealStatus = e?.target?.checked;
          />
        </div>
        {/* <div className="product-details "> */}

        <div className="product-heading purchasedpage-res">
          <table>
            <tr>
              {/* <th>Store name</th>
              <th>Category</th>
              <th>Bsr rank</th>
              <th>Profit</th>
              <th>Roi</th>
              <th>Country</th>
              <th>Created on</th> */}
            </tr>
            <tr>
              <td>{value.store_name}</td>
              <td>
                {value?.cat_lists[0]?.name
                  ? value?.cat_lists[0]?.name
                  : "Uncategorized"}
              </td>
              <td>{`${value.ninety_days_rank ?? 0}`}</td>
              <td>${value.net_profit}</td>
              <td>{value.roi}%</td>
              <td>{value.country_name}</td>
              <td>{moment(value.created_on).format("L")}</td>
              <td>
                <button
                  className="graph-btn"
                  onClick={() => handlesViewdeal(value.store_name)}
                >
                  view deal
                </button>
              </td>
              {/* <td>
                    {value?.asin ? (
                      <button
                        className="graph-btn"
                        onClick={() => handlesViewdeal(value.store_name)}
                      >
                        view deal
                      </button>
                    ) : (
                      <button
                        className={"amazon-btn dis-block"}
                        onClick={() => handleshowdeal(value)}
                        disabled={disablebutton}
                      >
                        buy deal
                      </button>
                    )}
                  </td> */}
            </tr>
          </table>
          <div class="purchased-rewapmed-res-parent">
            <div class="purchased-rewamped-res-up">
              <div class="purchased-rewamped-screen1">
                <h3>{value.store_name}</h3>
                <h6>
                  {value?.cat_lists[0]?.name
                    ? value?.cat_lists[0]?.name
                    : "Uncategorized"}{" "}
                  | {moment(value.created_on).format("L")}
                </h6>
              </div>
              <div class="purchased-rewamped-screen2">
                <button
                  className="graph-btn"
                  onClick={() => handlesViewdeal(value.store_name)}
                >
                  view deal
                </button>
              </div>
            </div>
            <div class="purchased-rewamped-res-down">
              <div class="purchased-rewamped-box">
                <h4>BSR Rank</h4>
                <h6>{`${value.ninety_days_rank ?? 0}`}</h6>
              </div>
              <div class="purchased-rewamped-box">
                <h4>Profit</h4>
                <h6>${value.net_profit}</h6>
              </div>
              <div class="purchased-rewamped-box">
                <h4>ROI</h4>
                <h6>{value.roi}%</h6>
              </div>
              <div class="purchased-rewamped-box">
                <h4>Country</h4>
                <h6>{value.country_name}</h6>
              </div>
            </div>
          </div>
          {/* <h4 className="product-title pt-1 pb-1 storename-val"> </h4>

            <h5 className=" pt-1 pb-1 category-val"></h5>

            <h5 className="h5-productdmp pt-1 pb-1 bsr-val"></h5>

            <h5 className="profit-val"></h5>
            <h5 className="roi-val"></h5>

            <h5 className="countryname-val"></h5>
            <h5 className="pt-1 pb-1 date-val"></h5> */}
        </div>

        {/* </div> */}

        <div></div>
      </div>
      {/* <div class="purchased-rewamped-res-parent">
          <div class="container">
            <div class="purchased-rewamped-deals">
              <h3>hh</h3>
              <h5>jj | 12/2/22</h5>
            </div>
          </div>
        </div> */}
      <div
        className={
          showdeal ? "product-info mt-1 showdeal" : "product-info mt-1 hidedeal"
        }
      >
        <div className="product-table-info mt-2 pl-4 mr-3">
          <div className="product-table pt-2">
            <div className="product-table-list-1">
              <h4 className=" product-table-value "> Product Name </h4>
              <h5 className="product-table-title productname"> {value.name}</h5>

              <h4 className="product-table-value product-table-custom">
                Net Profit{" "}
              </h4>
              <h5 className=" product-table-title">
                ${Number(value.net_profit)}
              </h5>
            </div>

            <div className="product-table-list-2">
              <h4 className="product-table-value "> Store Price </h4>
              <h5 className="product-table-title">
                ${Number(value.store_price)}
              </h5>

              <h4 className=" product-table-value product-table-custom purchased-roi-res">
                {" "}
                ROI
              </h4>
              <h5 className="product-table-title purchased-roi-res-title"> {Number(value.roi)}%</h5>
            </div>

            <div className="product-table-list-3 purchased-product-list-3">
              <h4 className="product-table-value"> Amazon Price </h4>
              <h5 className="product-table-title">
                ${Number(value.amazon_price)}
              </h5>

              <h4 className=" product-table-value product-table-custom">
                ASIN{" "}
                <span className="asinlink-icon">
                  {" "}
                  <BiLinkAlt />
                </span>
                {/* Link */}
              </h4>
              <a
                href={value.amazon_product_url}
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                <h5 className="product-table-title ">{value.asin}</h5>
              </a>
            </div>
            <div className="product-table-list-4 purchased-product-list-4">
              <h4 className=" product-table-value">Sellers Count</h4>
              <h5 className="product-table-title">
                {" "}
                {Number(value.sellers_count)}
              </h5>

              <h4 className=" product-table-value product-table-custom">
                Store{" "}
                <span className="asinlink-icon">
                  {" "}
                  <BiLinkAlt />
                </span>
              </h4>
              <a
                target="_blank"
                href={value.store_product_url}
                style={{ textDecoration: "underline" }}
              >
                <h5 className="product-table-title">{value.store_name}</h5>
              </a>
            </div>
          </div>
          {/* <hr className="rulerline" /> */}
          <h4
            className="product-offer-info pb-2 pt-1"
            style={{ display: "none" }}
          >
            Don't forget to make use of below offers!
          </h4>
        </div>
      </div>
    </div>

  );
}

export default Wishlistcard;
