import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./assets/css/style.css";
import "./assets/css/account-details.css";
import "./assets/css/extension.css";
import "./assets/css/learn-more.css";
import "./assets/css/responsive.css";
import "./assets/css/purchased.css";
import "./assets/css/customdealform.css";
import "./assets/css/thank-you.css";
import { KeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import "react-toastify/dist/ReactToastify.css";


import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import Loader from "./components/Loader";
 
const queryClient = new QueryClient()


// import reportWebVitals from './reportWebVitals';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <KeycloakProvider
    keycloak={keycloak}
    LoadingComponent={
      <Loader/>
    // <p>Loading...</p>
  }
    onEvent={(event, error) => console.log("onKeycloakEvent", event, error)}
    onTokens={(tokenData) => {
      localStorage.setItem("aoa_admin_token", tokenData?.token);
      
    }}
  >
    {/* <React.StrictMode> */}
    <QueryClientProvider client={queryClient}>
      <App />
    
    </QueryClientProvider>
    {/* </React.StrictMode> */}
  </KeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
