import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
// import * as serviceCaller from "../../../Services/index";
import * as serviceCaller from "../Services/index";
import { AiOutlineQuestionCircle} from "react-icons/ai";

import { toast } from "react-toastify";
export default function CustomdealForm({ isOpen, toggleModal,setIsOpen }) {
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [Category, setCategory] = useState();
  const [btndisable,setBtndisable] = useState(false);
  const [iserror,setIserror] = useState(false);


  useEffect(() => {
    getCategoryLists();
  }, []);

  let getCategoryLists = async () => {
    let category = await serviceCaller.getAllCategory();
    console.log("category : ", category?.data?.docs);
    setCategory(category?.data?.docs);
  };
  const onSubmit = async (data) => {
    setBtndisable(true)

  
    console.log("postreq-data",data)


    let response = await serviceCaller.addCustomdeal(data);
    console.log("req-response",response)

    if (response.status === 200) {
    
      
      if(response.data.message=="Deals requested Successfully"){
        // setLoading(false);
        toast.success(response.data.message);
         reset();
        toggleModal();
      }
    
    } else if(response.status !==200) {

      
      reset();
      toggleModal();
      toast.error(response.data.message);
    }
    setBtndisable(false)

    
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="customdeal-form-modal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div class="model-notify">
            <div class="model-notify-content">
              <span style={{ marginRight: "12px" , fontSize:"20px"}}>
                <AiOutlineQuestionCircle  />
              </span>
              <p>
                Enter the details below and a VA will be assigned to get your
                personalized deal
              </p>
            </div>
          </div>
          <div className="customdeal-flex">
            <label htmlFor="category">Category</label>
            <div>
              <select
                {...register("category", { required: true })}
                className="customdeal-select"
              >
                <option selected disabled hidden>
                  Please Select Category
                </option>
                {Category?.map((item) => (
                  <option value={item?._id}>{item?.name}</option>
                ))}
              </select>
              {/* <input type="text" className="customdeal-text-input"   {...register("category", { required: true })} /> */}
              {errors.category && (
                <p className="customdeal-error-msg"> enter category</p>
              )}
            </div>
          </div>

          <div className="customdeal-flex">
            <label htmlFor="roi">ROI</label>
            <div>
              <input
                type="text"
                className="customdeal-text-input"
                {...register("roi", { required: true })}
              />
              {errors.roi && <p className="customdeal-error-msg"> enter roi</p>}
            </div>
          </div>
          <div className="customdeal-flex">
            <label htmlFor="netprofit">Net Profit</label>
            <div>
              <input
                type="text"
                className="customdeal-text-input"
                {...register("netprofit", { required: true })}
              />
              {errors.netprofit && (
                <p className="customdeal-error-msg"> enter net profit</p>
              )}
            </div>
          </div>

          <div className="customdeal-flex">
            <label>BSR</label>
            <div>
              <select
                {...register("bsr", { required: true })}
                className="customdeal-select"
              >
                <option value="below 1%">Below 1%</option>
                <option value="below 1.5%">Below 1.5%</option>
                <option value="below 2%">Below 2%</option>
                <option value="below 3%">Below 3%</option>
              </select>
              {errors.bsr && <p className="customdeal-error-msg"> enter bsr</p>}
            </div>
          </div>
          <div className="customdeal-flex">
            <label>How many people can buy this deal</label>
            <div>
              <input
                type="number"
                className="customdeal-text-input"
                {...register("sharedpeople", {
                  required: true,
                  min: 0,
                  max: 20,
                })}
              />
              {errors.sharedpeople?.type === "required" && (
                <p className="customdeal-error-msg"> enter number of people</p>
              )}
              {(errors?.sharedpeople?.type === "max" ||
                errors?.sharedpeople?.type === "min") && (
                <p className="customdeal-error-msg">
                  shared people range between 1 to 20
                </p>
              )}
            </div>
          </div>
          <div className="customdeal-flex">
            <label>Hazmat</label>

            <div className="customdeal-flex-radio">
              <span>
                <input type="radio" {...register("hazmat")} value="yes" />{" "}
                <label>yes</label>
              </span>
              <span>
                <input type="radio" {...register("hazmat")} value="no" />{" "}
                <label>no</label>
              </span>
            </div>
            {errors.hazmat && (
              <p className="customdeal-error-msg"> select hazmat</p>
            )}
          </div>

          <div className="customdeal-flex">
            <label>Est monthly sales</label>
            <div>
              <select
                className="customdeal-select"
                {...register("estmonthlysales", { required: true })}
              >
                <option value="above 20">Above 20</option>
                <option value="above 40">Above 40</option>
                <option value="above 60">Above 60</option>
                <option value="above 80">Above 80</option>
              </select>
              {errors.estmonthlysales && (
                <p className="customdeal-error-msg"> enter est-monthly-sales</p>
              )}
            </div>
          </div>
          <div className="customdealbtn-conatiner">
            <button className="graph-btn" type="submit" disabled={btndisable}>
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}
